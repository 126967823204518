import React from 'react';
import {graphql} from 'gatsby';
import {Container, Row, Col} from 'react-bootstrap';
import {css} from '@emotion/core';
import {bp} from '../components/layout';

import Layout from './layout';
import SubCover from './sub-cover';
import Image from '../components/Image';
import PageContent from '../components/page-content';

import rehypeReact from "rehype-react";

const renderAst = new rehypeReact({
  createElement: React.createElement,
  // components: { 'h4': 'p' }
}).Compiler;

const splitAst = (elements, target) => {
  const groups = [];
  let currentGroup = null;
  const newGroup = attr => {
    const _group = { attr, el: [] };
    currentGroup = _group;
    groups.push(currentGroup);
    return _group;
  };

  const regex = new RegExp(`^\\[${target}\\]`);
  for (const el of elements) {
    if (el.children?.[0]?.value?.trim().match(regex)) {
      const text = el.children[0].value.replace(regex, '');
      newGroup(JSON.parse(text.trim()));
      continue;
    }
    if (currentGroup === null) {
      newGroup({});
    }
    currentGroup.el.push(el);
  }
  return groups;

};

const toGrid = (htmlAst) => {

  const ast = htmlAst.children.filter(el =>
    !(el.type === 'text' && el.value === '\n'));
  const rows = splitAst(ast, 'row');
  for (const row of rows) {
    if (row.attr.css) {
      row.attr.css = css(row.attr.css);
    }
    row.root = { attr: {} };
    if (row.attr.cssRoot) {
      row.root.attr.css = css(row.attr.cssRoot);
      delete row.attr.cssRoot;
    }
    const cols = splitAst(row.el, 'col').map(col => {
      if (col.attr.css) {
        col.attr.css = css(col.attr.css);
      }
      return {
        attr: col.attr,
        el: {
          ...htmlAst,
          children: col.el
        }
      };
    });
    row.el = cols;
  }
  // console.log(rows);
  return rows;
};

export default ({location, data}) => {
  const {
    use_grid,
    title_en,
    title_ja,
    caption,
    keywords,
    image_left,
    image_cover,
    description,
    footer_css
  } = data.markdownRemark.frontmatter;
  
  const imageCoverPath = image_cover ?
    image_cover.relativePath.split('/').slice(-1)[0] : null;

  const useGrid = Number.parseInt(use_grid) > 0;
  // console.log(JSON.stringify(data.markdownRemark.htmlAst));
  // const d = data.markdownRemark.htmlAst;
  // d.children = d.children.filter(el => el.tagName === 'h4');

  const renderWithoutGridSystem = () => (
    <PageContent>
      <Container fluid>
        <Row>
          <Col lg={4} className="no-padding">
            <div
              className="green-border-left"
            >
              <h2>{title_en}</h2>
              <div
                css={css`
                  font-size: 16px;
                  font-weight: 600;
                  margin-top: 15px;
                `}
              >
                {title_ja}
              </div>
            </div>
            <div
              css={css`
                margin-top: 30px;
                margin-bottom: 20px;
                font-size: 13px;
                line-height: 1.55em;
              `}
            >
              {keywords}
            </div>
            {
              image_left && (
                <Image
                  src={image_left.relativePath}
                  alt={`${title_en}-figure`}
                  style={{
                    maxWidth: '400px',
                    margin: '0 auto'
                  }}
                ></Image>
              )
            }
          </Col>
          <Col lg={8} className="no-padding">
            <div
              css={css`
                padding-left: 0px;
                margin-top: -35px;
                li {
                  font-size: 16px;
                }
                ${bp.sm} {
                  padding-left: 55px;
                }
              `}
            >
              {renderAst(data.markdownRemark.htmlAst)}
            </div>
          </Col>
        </Row>
      </Container>
    </PageContent>

  );
  
  const renderWithGridSystem = () => (
    <Container fluid css={css`padding: 0;`}>
      {
        toGrid(data.markdownRemark.htmlAst).map((row, i) => (
          <div {...row.root.attr}>
            <Row {...row.attr} key={`row-${i}`}>
              {
                row.el.map((col, j) => (
                  <Col {...col.attr} key={`col-${j}`}>
                    {renderAst(col.el)}
                  </Col>
                ))
              }
            </Row>
          </div>
        ))
      }
    </Container>
  );

  return (
    <Layout
      pageTitle={title_en}
      location={location}
      description={description}
      footerCss={footer_css}
    >
      <SubCover
        title={title_en}
        caption={caption}
        image={imageCoverPath}
      />
      { useGrid ?
        renderWithGridSystem() :
        renderWithoutGridSystem()
      }
    </Layout>
  );
};

export const query = graphql`
  query($absolutePath: String!) {
    markdownRemark(fileAbsolutePath: {eq: $absolutePath}) {
      htmlAst
      frontmatter {
        use_grid
        title_en
        title_ja
        caption
        keywords
        description
        footer_css
        image_cover {
          relativePath
        }
        image_left {
          relativePath
        }
      }
    }
  }
`;